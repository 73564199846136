import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Developer Contact</h1>
      <div
        style={{
          width: 500,
          margin: "0 auto",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: 12,
          border: "3px solid #333",
          padding: 20,
        }}
      >
        <div>
          <b>Email:</b> wildprogrammer1000@gmail.com
        </div>
        <div>
          <b>Tel:</b> +82)10-4106-6366
        </div>
      </div>
    </div>
  );
}

export default App;
